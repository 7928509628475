var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-5" }, [
      _c("div", { staticClass: "row mt-5" }, [
        _c("div", { staticClass: "col-md-12 col-lg-12 text-xl pl-4 pr-lg-4" }, [
          _c("h1", [
            _vm._v(
              " LA DIGITALIZZAZIONE DELL’ASSISTENZA DOMICILIARE AGLI ANZIANI TRAMITE AI E REALTÀ AUMENTATA "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row mt-5" }, [
        _c("div", { staticClass: "col-md-12 col-lg-12 text-xl pl-4 pr-lg-4" }, [
          _c("p", [
            _vm._v(
              " Grazie alla Regione Lombardia abbiamo sviluppato una piattaforma digitale avanzata."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Questa piattaforma è progettata per essere all'avanguardia nel settore, offrendo soluzioni efficaci. L'obiettivo principale è garantire all’utente finale una grossa comodità: attraverso la nostra piattaforma, l'utente avrà la capacità di richiedere una vasta gamma di servizi direttamente online."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Non si tratta solo di semplici servizi, ma anche di consulenze specialistiche e di ausili medici. Riconosciamo l'importanza di fornire strumenti e risorse che possono fare una differenza tangibile nella vita delle persone, soprattutto quando si tratta di salute e benessere."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Inoltre, la nostra piattaforma è dotata di funzionalità di analisi che permettono di rilevare e identificare ciò di cui l’utente potrebbe avere bisogno, garantendo che le soluzioni offerte siano sempre pertinenti e mirate. Questo approccio garantisce che stiamo effettivamente rispondendo a esigenze autentiche e reali, anziché fornire soluzioni generiche. Al centro della nostra missione c'è l'intenzione di creare un collegamento diretto tra le necessità dell'individuo e le soluzioni digitali innovative che possiamo offrire. "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row mx-lg-5 mx-2 mb-3" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("hr", { staticClass: "bg-primary" }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 col-md-12" }, [
              _c("div", { staticClass: "text-center ml-3" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: require("@/assets/images/tech-Fast.png") }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }